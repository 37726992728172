<div class="page-wrapper">
  <div class="page-inner">
    <div class="page-content-wrapper">
      <hch-page-header></hch-page-header>
      <main class="page-content">
        <router-outlet></router-outlet>
      </main>
      <hch-page-footer></hch-page-footer>
      <!-- <hch-page-footer *ngIf="showFooter()" [showInfo]="showFooterInfo()"></hch-page-footer> -->
      <hch-mobile-menu></hch-mobile-menu>
    </div>
  </div>
</div>
