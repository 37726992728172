<!-- ngbDropdown -->
<div
  class="position-relative for-desktop"
  dropdown
  #dropdown="bs-dropdown"
  [insideClick]="true"
  #addFilterDropdown
  id="addFilterDropdown"
  placement="top"
  (onShown)="scrollTo()"
  (onHidden)="closeModal()"
>
  <button class="btn btn-outline-primary dropdown-toggle filter-toggle" dropdownToggle aria-label="Add Filters">
    <img src="assets/images/icons/filter.svg" alt="" class="mr-2" />
    Add Filters
    <span *ngIf="appliedFilters.filterOptions" class="applied-filters">{{ appliedFilters.filterOptions }}</span>
  </button>
  <div *dropdownMenu class="dropdown-menu dropdown-menu-left">
    <hch-filters
      (closeEvent)="closeModal(); dropdown.isOpen = false"
      (applyFiltersEvent)="applyFilter($event); dropdown.isOpen = false"
    ></hch-filters>
  </div>
</div>
<div class="for-mobile">
  <button class="btn btn-outline-primary dropdown-toggle filter-toggle" (click)="openModal(template)">
    <img src="assets/images/icons/filter.svg" alt="" class="mr-2" />
    Add Filters
    <span *ngIf="appliedFilters.filterOptions" class="applied-filters">{{ appliedFilters.filterOptions }}</span>
  </button>

  <ng-template #template>
    <hch-filters
      (closeEvent)="closeModal()"
      (applyFiltersEvent)="applyFilter($event); dropdown.isOpen = false"
    ></hch-filters>
  </ng-template>
</div>
