import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hch-create-new-password',
  templateUrl: './create-new-password.component.html',
  styleUrls: ['./create-new-password.component.scss'],
})
export class CreateNewPasswordComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  token: string = '';
}
