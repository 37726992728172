import { Location } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
// import { BsModalService } from 'ngx-bootstrap/modal';

import { Unsubscriber } from 'src/app/core/extenders/unsubscriber';

import { AuthService } from 'src/app/core/services/auth.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { RoutingService } from 'src/app/_site-specific/services/routing/routing.service';

@Component({
  selector: 'hch-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent extends Unsubscriber implements OnInit {
  @Output() closeEvent = new EventEmitter();

  fullName: string = '';

  constructor(
    public authService: AuthService,
    private location: Location,
    private modalService: ModalService,
    public routingService: RoutingService
  ) {
    super();
  }

  ngOnInit(): void {}

  closeModal() {
    this.location.back();
  }

  login() {
    this.modalService.showLoginModal();
  }

  register() {
    this.modalService.showRegisterModal('Create Your Free Account');
  }

  logout() {
    this.authService.logout().subscribe();
  }

  onRealEstateAgent() {
    this.modalService.showNeedAgentModal();
  }

  onSupport() {
    this.modalService.showSupportModal();
  }
}
