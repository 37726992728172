export interface DropdownItem {
  label: string;
  value: string | number;
  icon?: string;
  disabled?: boolean;
  selected?: boolean;
}

export enum DropdownOptionsE {
  DISCOUNT = 'DISCOUNT', //= 'discount',
  HOME_PRICE = 'HOME_PRICE', //= 'homePrice',
  INTEREST = 'INTEREST', //= 'interest',
  LOAN_TYPE = 'LOAN_TYPE', //= 'loanType',
  LOT_SIZE = 'LOT_SIZE', //= 'lotSize',
  MAX_PRICE = 'MAX_PRICE', //= 'maxPrice',
  MAX_SQFT = 'MAX_SQFT', //= 'maxSqFt',
  MIN_PRICE = 'MIN_PRICE', //= 'minPrice',
  MIN_SQFT = 'MIN_SQFT', //= 'minSqFt',
  PERIOD = 'PERIOD', //= 'period',
  SORT_BY = 'SORT_BY', //= 'sortBy',
  TIMEFRAME = 'TIMEFRAME', //= 'timeFrame',
}

export const sortByItems: DropdownItem[] = [
  { label: 'Default', value: '-list_date' },
  { label: 'Price: High To Low', value: '-price' },
  { label: 'Price: Low To High', value: '+price' },
  // { label: 'List date', value: '+list_date' },
  { label: 'Beds', value: '+bedrooms' },
  { label: 'Baths', value: '+bathrooms' },
  { label: 'Square Footage', value: '-square_feet' },
  { label: 'Year built', value: '-year_built' },
];

export const sortByItem: DropdownItem = { label: 'Default', value: '-list_date' };

export const interestOptionsSchedule: DropdownItem[] = [
  { label: 'Selling', value: 0 },
  { label: 'Buying', value: 1 },
  { label: 'Discovering', value: 2 },
];

export const interestOptions: DropdownItem[] = [
  { label: 'Selling', value: 0 },
  { label: 'Refinancing', value: 1 },
  { label: 'Buying', value: 2 },
  { label: 'Other', value: 3 },
];

export const interestOptionsAgent: DropdownItem[] = [
  { label: 'Using Home Captain for my Business', value: 0 },
  { label: 'Selling', value: 1 },
  { label: 'Refinancing', value: 2 },
  { label: 'Buying', value: 3 },
  { label: 'Other', value: 4 },
];

export const timelineOptions: DropdownItem[] = [
  { label: '1 - 3 months', value: 0 },
  { label: '3 - 6 months', value: 1 },
  { label: '6 - 12 months', value: 2 },
];

export const discountingOptions: DropdownItem[] = [
  { label: 'Digital Full-Service', value: 0 },
  { label: 'Traditional Full-Service', value: 1 },
  { label: 'Do it Yourself', value: 2 },
];

export const homeValuePeriods: DropdownItem[] = [
  { label: '6 months', value: 6 },
  { label: '1 year', value: 13 },
  { label: '2 years', value: 25 },
  { label: '5 years', value: 60 },
];

export const trendsGraphFilters: DropdownItem[] = [
  { label: 'Min. Home Price', value: 0 },
  { label: 'Avg. Home Price', value: 1 },
  { label: 'Max. Home Price', value: 2 },
];

export const listingStatuses: DropdownItem[] = [
  { label: 'Show all', value: '', selected: true },
  { label: 'Active Listing', value: 'active', selected: false },
  { label: 'Pending', value: 'pending', selected: false },
  // { title: 'Coming Soon', value: 'coming_soon' },
  // { title: 'Sold', value: 'sold' },
];

export const lotSizesDropdown: DropdownItem[] = [
  { label: 'Any', value: 0 },
  { label: '2000+ sqft', value: 2000 },
  { label: '3000+ sqft', value: 3000 },
  { label: '4000+ sqft', value: 4000 },
  { label: '5000+ sqft', value: 5000 },
  { label: '7050+ sqft', value: 7050 },
  { label: '1/4 acre', value: 25000 },
  { label: '1/2 acre', value: 50000 },
  { label: '1 acre', value: 100000 },
  { label: '2 acres', value: 200000 },
  { label: '5 acres', value: 500000 },
  { label: '10 acres', value: 1000000 },
];

export const propertyTypes: DropdownItem[] = [
  { label: 'Any', value: '', selected: true },
  { label: 'Single Family Home', value: 'single', selected: false },
  { label: 'Townhome', value: 'townhouse', selected: false },
  { label: 'Duplex', value: 'duplex', selected: false },
  { label: 'Triplex', value: 'triplex', selected: false },
  { label: 'Ranch', value: 'ranch', selected: false },
  { label: 'Multi-family Home', value: 'multi', selected: false },
  { label: 'Mobile Home', value: 'mobile', selected: false },
  { label: 'Lots/Land', value: 'land', selected: false },
  { label: 'Commercial', value: 'commercial', selected: false },
];

export const minPriceRangesDropdown: DropdownItem[] = [
  { label: 'No Min', value: 0 },
  { label: '$50k', value: 50000 },
  { label: '$100k', value: 100000 },
  { label: '$150k', value: 150000 },
  { label: '$200k', value: 200000 },
  { label: '$250k', value: 250000 },
  { label: '$300k', value: 300000 },
  { label: '$350k', value: 350000 },
  { label: '$400k', value: 400000 },
  { label: '$450k', value: 450000 },
  { label: '$500k', value: 500000 },
  { label: '$550k', value: 550000 },
  { label: '$600k', value: 600000 },
  { label: '$650k', value: 650000 },
  { label: '$700k', value: 700000 },
  { label: '$750k', value: 750000 },
  { label: '$800k', value: 800000 },
  { label: '$850k', value: 850000 },
  { label: '$900k', value: 900000 },
  { label: '$950k', value: 950000 },
  { label: '$1M', value: 1000000 },
];

export const maxPriceRangesDropdown: DropdownItem[] = [
  { label: 'No Max', value: 0 },
  { label: '$50k', value: 50000 },
  { label: '$100k', value: 100000 },
  { label: '$150k', value: 150000 },
  { label: '$200k', value: 200000 },
  { label: '$250k', value: 250000 },
  { label: '$300k', value: 300000 },
  { label: '$350k', value: 350000 },
  { label: '$400k', value: 400000 },
  { label: '$450k', value: 450000 },
  { label: '$500k', value: 500000 },
  { label: '$550k', value: 550000 },
  { label: '$600k', value: 600000 },
  { label: '$650k', value: 650000 },
  { label: '$700k', value: 700000 },
  { label: '$750k', value: 750000 },
  { label: '$800k', value: 800000 },
  { label: '$850k', value: 850000 },
  { label: '$900k', value: 900000 },
  { label: '$950k', value: 950000 },
  { label: '$1M', value: 1000000 },
];

export const minFootagesDropdown: DropdownItem[] = [
  { label: 'No Min', value: 0 },
  { label: '250 sqft', value: 250 },
  { label: '500 sqft', value: 500 },
  { label: '750 sqft', value: 750 },
  { label: '1000 sqft', value: 1000 },
];

export const maxFootagesDropdown: DropdownItem[] = [
  { label: 'No Max', value: 0 },
  { label: '1000 sqft', value: 1000 },
  { label: '1250 sqft', value: 1250 },
  { label: '1500 sqft', value: 1500 },
  { label: '1750 sqft', value: 1750 },
  { label: '2000 sqft', value: 2000 },
  { label: '2250 sqft', value: 2250 },
];
