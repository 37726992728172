import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Unsubscriber } from 'src/app/core/extenders/unsubscriber';
import { DropdownItem } from 'src/app/core/models/dropdown.model';
import { RoutingService } from 'src/app/_site-specific/services/routing/routing.service';
import { SearchParams, UrlParserService } from 'src/app/core/services/url-parser.service';

@Component({
  selector: 'hch-baths-dropdown',
  templateUrl: './baths-dropdown.component.html',
  styleUrls: ['./baths-dropdown.component.scss'],
})
export class BathsDropdownComponent extends Unsubscriber implements OnInit, OnDestroy {
  @Input() mobile = false;
  @Output() changeEvent = new EventEmitter();

  url = [this.routingService.buyUrl.getValue()];
  bathsOptions: DropdownItem[] = [
    { label: 'Any', value: -1 },
    { label: '1+', value: 1 },
    { label: '2+', value: 2 },
    { label: '3+', value: 3 },
    { label: '4+', value: 4 },
    { label: '5+', value: 5 },
  ];
  selectedBath: DropdownItem = { label: 'Any', value: -1 };

  constructor(
    private urlParserService: UrlParserService,
    private route: ActivatedRoute,
    private routingService: RoutingService
  ) {
    super();
  }

  ngOnInit(): void {
    this.addSubscription = this.route.queryParams.subscribe((params) => {
      let bathrooms = params['minBathrooms'];
      if (bathrooms) {
        const filtered = this.bathsOptions.filter((item) => item.value == bathrooms);
        if (filtered.length > 0) {
          this.selectedBath = filtered[0];
        } else {
          this.selectedBath = this.bathsOptions[0];
        }
      } else {
        this.selectedBath = this.bathsOptions[0];
      }
    });
  }

  changeBathRoom(event: any) {
    this.selectedBath = event;

    if (this.selectedBath.value == -1) {
      this.urlParserService.removeParam(SearchParams.minBathrooms);
    } else {
      this.urlParserService.addParam(SearchParams.minBathrooms, this.selectedBath.value, this.url);
    }
  }
}
