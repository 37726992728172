<ng-container
  *ngIf="{
    buyParams: routingService.buyQueryParams | async,
    staticParams: routingService.staticQueryParams | async
  } as state"
>
  <footer class="bg-primary" id="footer-section" [ngClass]="{ 'vh-100': fullHeight }">
    <!-- Display mls disclaimer text if on full listing page -->
    <div *ngIf="displayMls" class="container">
      <div class="mls-info">
        <div *ngIf="iconUrl" class="mls-icon">
          <img [src]="iconUrl" alt="Broker Logo" />
        </div>
        <div *ngIf="complianceText" [innerHtml]="complianceText" class="compliance"></div>
      </div>
    </div>
    <div class="container">
      <p class="mb-3">Last Updated: {{ dateNow }}</p>
      <div class="d-flex flex-wrap border-bottom-white footer-contact">
        <div class="footer-logo">
          <img src="assets/images/HomeCaptainLogo-White.png" alt="home captain logo" />
        </div>
        <div class="footer-info">
          <div class="info-item">
            <span>Advertising Office Name: Home Captain Real Estate</span>
          </div>
          <div class="info-item">
            <img src="assets/images/icons/mail.svg" alt="home captain email" />
            <span>
              <a href="mailto:concierge@homecaptain.com" class="link focus-link">concierge@homecaptain.com</a>
            </span>
          </div>
          <div class="info-item">
            <img src="assets/images/icons/phone.svg" alt="home captain phone address" />
            <span><a href="tel:+18778474302" class="link focus-link">(877) 847 - 4302</a></span>
          </div>
          <div class="info-item">
            <img src="assets/images/icons/location.svg" alt="home captain location" />
            <!-- <span>1517 Voorhies Ave 2nd Floor Brooklyn, NY 11235</span> -->
            <span>53 Commerce Way, Woburn MA 01801</span>
          </div>
        </div>
      </div>

      <!-- <p class="d-flex align-items-center mb-3">California DRE #02140116</p> -->

      <div class="claimer">
        <p>California DRE: #02140116</p>

        <!-- Display Citi specific footer info -->
        <p>{{ mediaService.FOOTER_DISCLAIMER_TEXT | async }}</p>
        <p>
          Home Captain Inc. is a licensed real estate brokerage in the state of NY, License #10991214491. Contact #:
          <a href="tel:+18778474302" class="link focus-link">(877) 847-4302</a>
          . Home Captain Inc. fully supports and operates under the principles of the Fair Housing Act. A list of cities
          and towns that constitutes the market area that the broker serves.
        </p>
        <p>
          Home Captain Inc. is committed to ensuring digital accessibility for individuals with disabilities. We
          continuously work to improve the accessibility of our web experience for everyone, welcoming feedback, and
          accommodation requests. If you wish to report an issue or seek an accommodation, please contact us at
          <a href="tel:+18778474302" class="link focus-link">(877) 847-4302</a>
          .
        </p>
      </div>

      <div>
        <a class="licenses" [routerLink]="routingService.licensesUrl | async" routerLinkActive="nav-item-active">
          Home Captain License Numbers
        </a>
        <p>
          Providing brokerage services in the states of Florida, Virginia, Pennsylvania, Maryland, and Massachusetts.
        </p>
      </div>
      <div class="tos" style="padding-bottom: 0">
        <p>
          Copyright © {{ copyrightYear }}: By searching, you agree to the
          <button id="tos-modal-btn" class="btn btn-link focus-link" (click)="openTermsConditionsModal()">
            Terms of Use
          </button>
          and
          <button id="pp-modal-btn" class="btn btn-link focus-link" (click)="openPrivacyPolicyModal()">
            Privacy Policy
          </button>
        </p>
      </div>

      <img
        src="assets/images/equal-housing-opportunity.png"
        class="my-3"
        style="max-width: 100px"
        alt="Equal Housing Opportunity logo"
      />

      <ul class="footer-link">
        <!-- <div class="footer-link-container"> -->
        <li>
          <a
            class="item focus-link"
            routerLinkActive="footer-link-active"
            [routerLink]="routingService.buyUrl | async"
            [queryParams]="routingService.mergeParams(state.buyParams, state.staticParams)"
          >
            Buy
          </a>
        </li>
        <li>
          <a
            class="item focus-link"
            routerLinkActive="footer-link-active"
            [routerLink]="routingService.homeValueUrl | async"
            [queryParams]="state.staticParams"
          >
            Home Value
          </a>
        </li>
        <li *ngIf="routingService.teamUrl | async">
          <a
            class="item focus-link"
            routerLinkActive="footer-link-active"
            [routerLink]="routingService.teamUrl | async"
            [queryParams]="state.staticParams"
          >
            Our Team
          </a>
        </li>
        <!-- </div> -->
        <!-- <div class="footer-link-container"> -->
        <li *ngIf="!(authService.authorized | async)">
          <a
            class="item focus-link"
            routerLinkActive="footer-link-active"
            tabindex="0"
            (click)="login()"
            (keydown.enter)="login()"
            href="javascript:void(0);"
          >
            Sign In
          </a>
        </li>
        <li *ngIf="!(authService.authorized | async)">
          <a
            class="item focus-link"
            tabindex="0"
            routerLinkActive="footer-link-active"
            (click)="register()"
            (keydown.enter)="register()"
            href="javascript:void(0);"
          >
            Register
          </a>
        </li>
        <!-- </div> -->
      </ul>
    </div>
  </footer>
</ng-container>
