import { Component, OnInit, Output, EventEmitter, Input, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { catchError, combineLatest, distinctUntilChanged, finalize, throwError, tap } from 'rxjs';

import { BuyService } from 'src/app/buy/buy.service';
import { states } from 'src/app/core/data/state-options';
import { Unsubscriber } from 'src/app/core/extenders/unsubscriber';
import { AuthService } from 'src/app/core/services/auth.service';

interface State {
  value: boolean;
  text: string;
  postal: string;
}

@Component({
  selector: 'hch-state-selector',
  templateUrl: './state-selector.component.html',
  styleUrls: ['./state-selector.component.scss'],
})
export class StateSelectorComponent extends Unsubscriber implements OnInit, AfterViewInit {
  notice = false;
  needToSaveStateSelection = false;

  selectedState?: State;
  options: State[] = states;

  @Input() labelColor: 'black' | 'white' = 'black';
  @Output() showSearch = new EventEmitter<boolean>();

  constructor(private authService: AuthService, private route: ActivatedRoute, private buyService: BuyService) {
    super();
    this.selectedState = states[0];
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.addSubscription = combineLatest([this.route.queryParams, this.authService.currentUser]).subscribe(
        ([params, user]) => {
          if (user) {
            if (!user.currentStateOfResidence) {
              this.needToSaveStateSelection = true;
              this.buyService.showStateSelector.next(true);
              this.buyService.showSearch.next(false);
              if (this.selectedState && this.selectedState.value) {
                this.updateSelectedState(this.selectedState);
              }
            } else {
              this.needToSaveStateSelection = false;
              this.buyService.showStateSelector.next(false);
              this.buyService.showSearch.next(true);
            }
          }

          if (params['state']) {
            const state = this.options.filter((state) => state.postal === params['state'])[0];
            this.selectedState = state;
            this.notice = false;
            this.buyService.showStateSelector.next(false);
            this.buyService.showSearch.next(true);
          }
          if (params['position']) {
            this.notice = false;
            this.buyService.showStateSelector.next(false);
            this.buyService.showSearch.next(true);
          }
        }
      );
    }, 0);
  }

  ngOnInit(): void {}

  updateSelectedState(state: State) {
    if (state.value) {
      this.selectedState = state;
      this.notice = false;
      // Make request to backend to save selected state for user
      if (this.needToSaveStateSelection) {
        this.addSubscription = this.authService
          .saveSelectedState(state.text)
          .pipe(
            finalize(() => {
              this.needToSaveStateSelection = false;
              this.buyService.showSearch.next(true);
              this.buyService.showStateSelector.next(false);
            }),
            catchError((error) => {
              console.error(error);
              return throwError(() => error);
            })
          )
          .subscribe();
      } else {
        this.buyService.showStateSelector.next(false);
        this.buyService.showSearch.next(true);
      }
    } else {
      this.notice = true;
      this.showSearch.emit(false);
      this.buyService.showStateSelector.next(true);
      this.buyService.showSearch.next(false);
    }
  }
}
