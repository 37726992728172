import { Component, Input, OnInit, forwardRef, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { map, Observable, startWith } from 'rxjs';

import { DropdownItem } from 'src/app/core/models/dropdown.model';
import { DropdownOptionsE } from 'src/app/core/models/dropdown.model';

@Component({
  selector: 'hch-editable-floating-dropdown',
  templateUrl: './editable-floating-dropdown.component.html',
  styleUrls: ['./editable-floating-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditableFloatingDropdownComponent),
      multi: true,
    },
  ],
})
export class EditableFloatingDropdownComponent implements OnInit, ControlValueAccessor {
  @Output() changes = new EventEmitter<any>();
  @Output() isOpen = new EventEmitter<boolean>();
  @Output() tabPress = new EventEmitter<boolean>();
  @Output() shifttabPress = new EventEmitter<boolean>();

  private _value: DropdownItem = {
    label: '',
    value: '',
  };

  get value(): DropdownItem {
    return this._value;
  }

  @Input()
  set value(val: DropdownItem) {
    this._value = val;
    this.selectedItem = val;
  }

  @Input() category: keyof typeof DropdownOptionsE = 'TIMEFRAME';
  @Input() disabled: boolean = false;
  @Input() icon: string = ''; // icon url
  @Input() items: DropdownItem[] = [];
  @Input() placeholder: string = '';
  @Input() styles: any;

  @Input() mask: any = '';
  @Input() prefix: string = '';
  @Input() suffix: string = '';
  @Input() thousandSeparator: string = '';

  // inputForm: FormGroup;
  input = new FormControl('');

  selectedItem: DropdownItem = {
    label: '',
    value: '',
  };

  filteredItems = new Observable<DropdownItem[]>();

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(private fb: FormBuilder) {}

  formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  ngOnInit() {
    this.filteredItems = this.input.valueChanges.pipe(
      startWith(''),
      map((value) => {
        if (value) {
          const onlyNumbers = value.replace(/[^0-9]*/g, '');
          const num = Number(onlyNumbers);
          if (isNaN(num)) return value;
          return onlyNumbers;
        }
        return '';
      }),
      map((value) => this._filter(value))
    );
  }

  private _filter(value: string | number | null) {
    if (!value) return this.items;
    return this.items.filter((item) => {
      return typeof item.label == 'string' && item.label.includes(String(value));
    });
  }

  selectItem(value?: string | number | null) {
    if (!value) {
      value = this.input.value;
    }
    this.changes.emit(value);
    this.onChange(value);
    this.onTouched(value);
  }

  writeValue(value: any): void {
    this.selectedItem = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
