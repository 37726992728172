import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BuyService } from 'src/app/buy/buy.service';
import { Unsubscriber } from 'src/app/core/extenders/unsubscriber';
import { RoutingService } from 'src/app/_site-specific/services/routing/routing.service';

@Component({
  selector: 'hch-buy-layout',
  templateUrl: './buy-layout.component.html',
  styleUrls: ['./buy-layout.component.scss'],
})
export class BuyLayoutComponent extends Unsubscriber implements OnInit {
  constructor(private route: ActivatedRoute, private buyService: BuyService, private routingService: RoutingService) {
    super();
  }

  ngOnInit(): void {
    this.addSubscription = this.route.params.subscribe((params) => {
      this.routingService.setActivatedRoute(params);
    });
    this.addSubscription = this.route.queryParams.subscribe((params) => {
      this.buyService.initialize(params);
    });
  }
}
