import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { Provider, ProviderE } from 'src/app/core/models/listing.model';
import { HomeValueSearchParams, SearchParams } from 'src/app/core/services/url-parser.service';

type ParamsI = Params | null | undefined;

/**
 * Handles base route configuration for the Citi site
 *
 * DO NOT IMPORT THIS FILE, ONLY IMPORT routing.service.ts
 *
 * File replacements handle the injection of this service when building with the corresponding configuration
 */
@Injectable({
  providedIn: 'root',
})
export class RoutingService {
  constructor() {}

  /**
   * Stores query parameters for the Buy page.
   */
  buyQueryParams = new BehaviorSubject<Params>({});

  /**
   * Stores query parameters for the Home Value page.
   */
  homeValueQueryParams = new BehaviorSubject<Params>({});

  /**
   * Stores global / static query parameters.
   * These params should exist on all routes.
   */
  staticQueryParams = new BehaviorSubject<Params>({});

  /**
   * Stores URL parameters.
   */
  params = new BehaviorSubject<Params>({});

  nmls = new BehaviorSubject<string>(''); // sample id: 1921777
  isMRTemplate = new BehaviorSubject<boolean>(false);

  homeUrl = new BehaviorSubject<string>('/home');
  buyUrl = new BehaviorSubject<string>('/buy');
  buyFilterUrl = new BehaviorSubject<string>('/buy/filter');
  buySearchUrl = new BehaviorSubject<string>('/buy/search');
  favoriteUrl = new BehaviorSubject<string>('/favorite');
  fullListingUrl = new BehaviorSubject<string>('/full-listing');
  homeValueUrl = new BehaviorSubject<string>('/homevalue');
  homeValueCategoryUrl = new BehaviorSubject<string>('/homevalue/category');
  homeValueEstimationUrl = new BehaviorSubject<string>('/homevalue/estimation');
  licensesUrl = new BehaviorSubject<string>('/licenses');
  settingsUrl = new BehaviorSubject<string>('/settings');
  teamUrl = new BehaviorSubject<string>('/team');
  userMenuUrl = new BehaviorSubject<string>('/user-menu');

  /**
   * Captures params in url, like nmls id, listing id, or provider
   */
  setParams(params: Params) {
    // const keys = Object.keys(params);
    // const map: Params = {};
    // keys.map((key) => {
    //   if (key === 'id' || key === 'campaignid') {
    //     map[key] = params[key];
    //   }
    // });
    // this.params.next(map);
    this.params.next(params);
  }

  /**
   * Captures query params in url, like city and state when searching and filtering on the Buy page.
   */
  setBuyQueryParams(params: Params) {
    this.buyQueryParams.next(params);
  }

  /**
   * Sets static query params that exist on all routes.
   * By default, the Buy page SearchParams are excluded from this list.
   */
  setStaticQueryParams(params: Params) {
    let staticParams: Params = {};

    const keys = Object.entries(params);
    if (keys.length == 0) {
      this.staticQueryParams.next({});
      return;
    }

    for (let [key, value] of keys) {
      if (!(key in SearchParams) && !(key in HomeValueSearchParams)) {
        staticParams[key] = value;
      }
    }

    if (Object.keys(staticParams).length != 0) {
      this.staticQueryParams.next(staticParams);
    }
  }

  /**
   * Merges any number of Param inputs into one object.
   * Params with the same key will be overwritten by the right most argument.
   */
  mergeParams(...args: ParamsI[]) {
    let mergedParams: Params = {};
    for (let p of args) {
      if (p && Object.keys(p).length != 0) {
        mergedParams = { ...mergedParams, ...p };
      }
    }
    return mergedParams;
  }

  /**
   * Sets the activated route Urls with the NMLS parameter, if it exists.
   */
  setActivatedRoute(params: Params) {
    this.setParams(params);
    if (params['nmls']) {
      let nmls = params['nmls'];
      this.nmls.next(nmls);
      this.isMRTemplate.next(true);
      this.homeUrl.next(`/home/${nmls}`);
      this.buyUrl.next(`/${nmls}/buy`);
      this.buyFilterUrl.next(`/${nmls}/buy/filter`);
      this.buySearchUrl.next(`/${nmls}/buy/search`);
      this.favoriteUrl.next(`/${nmls}/favorite`);
      this.fullListingUrl.next(`/${nmls}/full-listing`);
      this.homeValueUrl.next(`/${nmls}/homevalue`);
      this.homeValueCategoryUrl.next(`/${nmls}/homevalue/category`);
      this.homeValueEstimationUrl.next(`/${nmls}/homevalue/estimation`);
      this.licensesUrl.next(`/${nmls}/licenses`);
      this.teamUrl.next(`/${nmls}/team`);
      this.settingsUrl.next(`/${nmls}/settings`);
      this.userMenuUrl.next(`/${nmls}/user-menu`);
    }
  }

  /**
   * Formats the full listing URL given a listing id and the provider name.
   *
   * @param id The listing id
   * @param provider The listing provider, either 'listhub' or 'realstaq'
   * @returns A url to the full listing page
   */
  getFullListingURL(id: string, provider: Provider) {
    let providerId = '001';
    if (provider == ProviderE.LISTHUB) providerId = '001';
    else if (provider == ProviderE.REALSTAQ) providerId = '002';

    return `${this.fullListingUrl.getValue()}/${id}/${providerId}`;
  }
}
