import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hch-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss'],
})
export class MobileHeaderComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
