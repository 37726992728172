import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

export interface LoanOfficer {
  name?: string;
  id?: string;
  phone?: string;
  email?: string;
  website?: string;
  message?: string;
  picture?: string;
  url?: string;
  title?: string;
}
@Injectable({
  providedIn: 'root',
})
export class LoanOfficerService {
  constructor(private http: HttpClient) {}

  getLoanOfficerInfo(email: string) {
    return this.http.get<LoanOfficer>(environment.HUBAPI_BASE_URL + '/loan-officer/' + email);
  }

  getLoanOfficerId(nmlsid: string) {
    return this.http.get<LoanOfficer>(environment.HUBAPI_BASE_URL + '/loan-officer/?nmlsid=' + nmlsid);
  }

  getNMLS(id: string) {
    return this.http.get<LoanOfficer>(environment.HUBAPI_BASE_URL + '/loan-officer/get-nmls/' + id);
  }
}
