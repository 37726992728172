import { Component, OnInit } from '@angular/core';
import { RoutingService } from 'src/app/_site-specific/services/routing/routing.service';

@Component({
  selector: 'hch-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit {
  constructor(public routingService: RoutingService) {}

  ngOnInit(): void {}
}
