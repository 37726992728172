import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';

// Handles the MLS data text that is displayed in the footer

@Injectable({
  providedIn: 'root',
})
export class FooterService {
  private mlsInfo: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private mlsIcon: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor() {}

  setMlsInfo(mlsInfo: string): void {
    this.mlsInfo.next(mlsInfo);
  }
  setMlsIcon(mlsIcon: string): void {
    if (mlsIcon.includes('listhub')) {
      const url = this.getIconPathFromUrl(mlsIcon);
      this.mlsIcon.next(url);
    } else if (mlsIcon) {
      this.mlsIcon.next(mlsIcon);
    } else {
      this.mlsIcon.next('');
    }
  }

  getMlsInfo(): BehaviorSubject<string> {
    return this.mlsInfo;
  }
  getMlsIcon(): BehaviorSubject<string> {
    return this.mlsIcon;
  }

  getIconPathFromUrl(url: string) {
    if (!url.includes('listhub')) {
      return url;
    }

    let pieces = url.split('/');
    let fileName = pieces[pieces.length - 1];
    if (environment.production) {
      return 'https://cdn.myvolly.io/homecaptain/broker-logos/' + fileName;
    }
    return 'https://cdn.uat.myvolly.io/homecaptain/broker-logos/' + fileName;
  }
}
