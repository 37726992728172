import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'hch-add-filters',
  templateUrl: './add-filters.component.html',
  styleUrls: ['./add-filters.component.scss'],
})
export class AddFiltersComponent implements OnInit, AfterViewInit {
  opening = false;
  appliedFilters = {
    filterOptions: 0,
    filters: {},
  };
  isDropup = false;

  config = {
    backdrop: false,
    ignoreBackdropClick: true,
  };
  modalRef?: BsModalRef;

  @Output() changeShown = new EventEmitter();
  @Output() changeFilter = new EventEmitter();

  @ViewChild('addFilterDropdown') filterDropdown?: ElementRef;

  constructor(private modalService: BsModalService) {}

  openModal(template: TemplateRef<any>) {
    this.opening = true;
    this.modalRef = this.modalService.show(template, this.config);
    this.changeShown.emit(this.opening);
  }

  closeModal() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
    this.opening = false;
    this.changeShown.emit(this.opening);
  }

  ngOnInit(): void {
    this.detectDropup();
  }

  ngAfterViewInit() {
    this.detectDropup();
  }

  scrollTo() {
    this.opening = true;
    this.changeShown.emit(this.opening);
  }

  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //   this.detectDropup();
  // }

  // @HostListener('window:scroll', ['$event'])
  // onScroll(event) {
  //   this.detectDropup();
  // }

  detectDropup() {
    if (!this.filterDropdown) return;
    if (this.filterDropdown.nativeElement.getBoundingClientRect().top >= 400) {
      this.isDropup = true;
    } else {
      if (this.isDropup) {
        this.scrollTo();
      }
      this.isDropup = false;
    }
  }

  applyFilter(event: any) {
    this.appliedFilters = event;
    this.changeFilter.emit(this.appliedFilters.filters);
  }
}
