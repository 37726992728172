import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, throwError, catchError, retry } from 'rxjs';

import { environment } from 'src/environments/environment';
import { Unsubscriber } from '../extenders/unsubscriber';
import { AuthService } from '../services/auth.service';
import { ModalService } from '../services/modal.service';

@Injectable()
export class RequestInterceptor extends Unsubscriber implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router, private modalService: ModalService) {
    super();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const originHostname = `${window.location.protocol}//${window.location.host}`;
    const modifiedReq = req.clone({
      headers: req.headers.set('originating-hostname', originHostname),
      withCredentials: req.url.includes(environment.HUBAPI_BASE_URL) ? true : false,
    });
    return next.handle(modifiedReq).pipe(
      retry(0),
      catchError((res: HttpErrorResponse) => {
        switch (res.status) {
          case 400:
            break;
          case 401:
            this.authService.authorized.next(false);
            const modal = this.modalService.showLoginModal();
            this.addSubscription = modal.onHidden?.subscribe(() => {
              if (!this.authService.authorized.getValue()) {
                this.addSubscription = this.authService.logout().subscribe();
              }
            });
            break;
          case 403:
            break;
          case 404:
            break;
          case 500:
            break;
          default:
            break;
        }
        if (res.error && res.error.errors) {
          return throwError(() => res.error.errors);
        } else {
          return throwError(() => res.error);
        }
      })
    );
  }
}
