import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Listing } from '../models/listing.model';

@Injectable({
  providedIn: 'root',
})
export class DataTreeApiService {
  constructor(private http: HttpClient) {}

  baseUrl = environment.HUBAPI_BASE_URL + '/dt-api';

  getDataFromDataTreeListing(data: any): Listing {
    return {
      address: `${data.HouseNumber} ${String(data.Street_Name).trim()} ${String(data.Street_Type).trim()}`,
      bathrooms: data.Bathrooms || data.BathRooms,
      bedrooms: data.Bedrooms || data.BedRooms,
      city: String(data.City).trim(),
      country: 'United States',
      description: '',
      // distance: data.Distance || data.DistanceFromSubject,
      // formattedAddress: String(
      //   (data.Address ? data.Address + ', ' : data.Street_Name ? data.Street_Name + ', ' : '') +
      //     (data.City ? data.City + ' ' : '') +
      //     (data.State ? data.State + ' ' : '') +
      //     (data.Zip ? data.Zip : '')
      // ).trim(),
      // geoData: {
      //   cityId: '',
      //   cityName: String(data.City).trim(),
      //   neighborhoodId: '',
      //   neighborhoodName: '',
      //   schoolDistrictId: '',
      //   schoolDistrictName: '',
      //   zipId: '',
      //   zipName: data.Zip,
      // },
      listingId: data.Id || data.REF_ID,
      modificationTimestamp: data.ListingData || data.LastListedDate,
      // location: [data.Latitude, data.Longitude],
      latitude: data.Latitude,
      longitude: data.Longitude,
      lotSizeArea: data.LostSizeSqFt || data.LotSqFt,
      mlsId: data.REF_ID || data.Id,
      media: {
        photos: [],
      },
      // mlsListingId: data.REF_ID || data.Id,
      // mlsUpdateDate: data.ListingDate || data.LastListedDate,
      price: data.SalePrice,
      // priceDisplay: this.formatNumber(data.SalePrice),
      // priceShort: this.formatNumber(data.SalePrice / 1000) + 'k',
      squareFeet: data.SqFoot,
      state: data.State,
      standardStatus: data.PropertyStatus || data.ListingStatus,
      yearBuilt: data.YearBuilt,
      zipcode: data.Zip,
    } as Listing;
  }

  formatNumber(value: any) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    return formatter.format(value);
  }

  getReport(data: any) {
    return this.http.post(this.baseUrl + '/getReport', { query: data });
  }

  getMultiLineReport(data: any) {
    return this.http.post(this.baseUrl + '/getMultiLineReport', { query: data });
  }
}
