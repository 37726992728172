<div class="filter-container">
  <div class="filter-header">
    <img
      src="assets/icons/chevron-left-blue.svg"
      alt="back"
      class="back-btn"
      (click)="closeModal()"
      role="button"
      aria-label="Close Filter Dropdown"
    />
    <h5>Add Filters</h5>
    <button class="btn btn-link" (click)="reset()">reset</button>
  </div>
  <div class="filter-body">
    <div role="group" aria-labelledby="price-range-label" class="filter-item">
      <h6 class="mb-3" id="price-range-label">Price Range</h6>
      <div class="d-flex justify-content-between align-items-center">
        <hch-editable-floating-dropdown
          placeholder="Min Price"
          category="MIN_PRICE"
          mask="separator.0"
          prefix="$"
          thousandSeparator=","
          [items]="minPriceRanges"
          [value]="minPrice"
          (changes)="changeMinPrice($event)"
        ></hch-editable-floating-dropdown>
        <div class="range"></div>
        <hch-editable-floating-dropdown
          placeholder="Max Price"
          category="MAX_PRICE"
          mask="separator.0"
          prefix="$"
          thousandSeparator=","
          [items]="maxPriceRanges"
          [value]="maxPrice"
          (changes)="changeMaxPrice($event)"
        ></hch-editable-floating-dropdown>
        <div class="error" *ngIf="error">
          <p>{{ error }}</p>
        </div>
      </div>
    </div>
    <div role="group" aria-labelledby="bedrooms-label" class="filter-item d-flex justify-content-between">
      <h6 id="bedrooms-label">Bedrooms</h6>
      <div class="spin-minus-plus">
        <button
          (click)="decreaseBedroom(); checkAppliedFilters()"
          aria-label="Remove one bedroom from minimum bedrooms"
        >
          <img src="assets/images/icons/circle-minus.svg" alt="Subtract one bedroom" />
        </button>
        {{ beds[bedrooms] }}
        <button (click)="increaseBedroom(); checkAppliedFilters()" aria-label="Add one bedroom to minimum bedrooms">
          <img src="assets/images/icons/circle-plus.svg" alt="Add one bedroom" />
        </button>
      </div>
    </div>
    <div role="group" aria-labelledby="bathrooms-label" class="filter-item d-flex justify-content-between">
      <h6 id="bathrooms-label">Bathrooms</h6>
      <div class="spin-minus-plus">
        <button
          (click)="decreaseBathroom(); checkAppliedFilters()"
          aria-label="Remove one bathroom from minimum Bathrooms"
        >
          <img src="assets/images/icons/circle-minus.svg" alt="Subtract one bathroom" />
        </button>
        {{ baths[bathroom] }}
        <button (click)="increaseBathroom(); checkAppliedFilters()" aria-label="Add one bathroom to minimum bathrooms">
          <img src="assets/images/icons/circle-plus.svg" alt="Add one bathroom" />
        </button>
      </div>
    </div>
    <div role="group" aria-labelledby="property-type" class="filter-item">
      <h6 id="property-type" class="mb-3">Property Type</h6>
      <div class="custom-control custom-checkbox checkbox-list-item" *ngFor="let item of propertyTypes; let i = index">
        <input
          type="checkbox"
          class="custom-control-input"
          [id]="'propertyType' + i"
          [(ngModel)]="item.selected"
          (change)="updatePropertyTypes(i)"
        />
        <label class="custom-control-label" [for]="'propertyType' + i">{{ item.label }}</label>
      </div>
    </div>
    <div role="group" aria-labelledby="square-footage" class="filter-item">
      <h6 id="square-footage" class="mb-3">Square Footage</h6>
      <div class="d-flex justify-content-between align-items-center">
        <hch-floating-dropdown
          placeholder="Min"
          category="MIN_SQFT"
          [items]="minFootages"
          (change)="setMinSqFt($event)"
        ></hch-floating-dropdown>
        <div class="range"></div>
        <hch-floating-dropdown
          placeholder="Max"
          category="MAX_SQFT"
          [items]="maxFootages"
          (change)="setMaxSqFt($event)"
        ></hch-floating-dropdown>
      </div>
    </div>

    <div role="group" aria-labelledby="lot-size" class="filter-item">
      <h6 id="lot-size" class="mb-3">Lot size</h6>
      <hch-floating-dropdown
        placeholder="Size"
        category="LOT_SIZE"
        [items]="lotSizes"
        (change)="setLotSize($event)"
      ></hch-floating-dropdown>
    </div>

    <div role="group" aria-labelledby="year-built" class="filter-item">
      <h6 id="year-built" class="mb-3">Year Built</h6>
      <div class="d-flex justify-content-between align-items-center">
        <input
          type="number"
          class="form-control"
          [class.is-invalid]="minYear && (minYear < 1900 || minYear > thisYear)"
          placeholder="Min Year"
          [max]="thisYear"
          min="1900"
          (change)="changeYear()"
          [(ngModel)]="minYear"
        />
        <div class="range"></div>
        <input
          type="number"
          class="form-control"
          [class.is-invalid]="maxYear && (maxYear < 1900 || maxYear > thisYear)"
          placeholder="Max Year"
          [max]="thisYear"
          min="1900"
          (change)="changeYear()"
          [(ngModel)]="maxYear"
        />
      </div>
      <div class="mt-1 text-xs text-danger" *ngIf="maxYear && minYear && (maxYear > thisYear || minYear > thisYear)">
        Can't be a future year
      </div>
    </div>

    <div role="group" aria-labelledby="status" class="filter-item">
      <h6 id="status" class="mb-3">Status</h6>
      <div class="custom-control custom-checkbox checkbox-list-item" *ngFor="let item of status; let i = index">
        <input
          type="checkbox"
          class="custom-control-input"
          [id]="'status' + i"
          [(ngModel)]="item.selected"
          (change)="updateStatus(i)"
        />
        <label class="custom-control-label" [for]="'status' + i">{{ item.label }}</label>
      </div>
    </div>
  </div>
  <div class="filter-footer">
    <button class="btn btn-primary w-100" (click)="applyFilters()" [disabled]="!isEnableApply">Apply Filters</button>
  </div>
</div>
