<div dropdown #savedSearchDropdown="bs-dropdown" class="position-relative hch-save-search-dropdown" placement="top">
  <button
    class="btn btn-outline-primary btn-md dropdown-toggle save-search-btn"
    (click)="toggleDropdown()"
    [disabled]="disabledSearchButton || savedSearchDropdown.isOpen || totalElements == 0"
    [attr.aria-expanded]="savedSearchDropdown.isOpen"
  >
    Save Search
  </button>

  <div *dropdownMenu cdkTrapFocus class="dropdown-menu dropdown-menu-left save-search">
    <button autofocused class="close-btn" (click)="closeDropdown()" aria-label="Close Saved Search Modal">
      <img src="assets/icons/close.svg" alt="Close Saved Search Modal" />
    </button>
    <div class="dropdown-body auth-form">
      <h5>Save Your Search!</h5>
      <div class="form-group">
        <input
          id="name-your-search"
          type="text"
          class="text-s"
          placeholder="Name Your Search"
          [(ngModel)]="searchName"
        />
        <label for="name-your-search" class="placeholder text-s">Name Your Search</label>
      </div>
      <div class="desc" *ngIf="searchValue">
        <div>
          For sale:
          {{
            !searchValue['minPrice'] || searchValue['minPrice'] == 'No Min'
              ? 0
              : (searchValue['minPrice'] | currency : 'USD' : 'symbol' : '1.0-0')
          }}
          -
          {{
            !searchValue['maxPrice'] || searchValue['maxPrice'] == 'No Max'
              ? 'No Max'
              : (searchValue['maxPrice'] | currency : 'USD' : 'symbol' : '1.0-0')
          }}
        </div>
        <div *ngIf="searchValue['propertyTypes']">
          <span>Property types:</span>
          <span *ngFor="let item of searchValue['propertyTypes'].split(',')">{{ item }}</span>
        </div>
        <div>
          <span>{{ searchValue['minBedrooms'] > 0 ? searchValue['minBedrooms'] + '+' : 'Any' }} beds,</span>
          <span>{{ searchValue['minBathrooms'] > 0 ? searchValue['minBathrooms'] + '+' : 'Any' }} baths</span>
          <span *ngIf="enableMore">and more</span>
        </div>
      </div>
      <!-- <h6>Notification Settings</h6>
      <div class="option-group">
        <hch-radio-group
          legend="Preferred Email Frequency"
          name="notiSetting"
          [value]="notiSetting"
          [options]="notiOptions"
          (changeValue)="notiSetting = $event"
        ></hch-radio-group>
      </div> -->
      <button class="btn btn-primary w-100 submit" (click)="saveSearch()">Save</button>
    </div>
  </div>
</div>
