<div
  class="home-card"
  *ngIf="listing && !loading"
  [ngClass]="{
    'saved-card': savedCard,
    'map-card': mapCard,
    'for-sale': forSale,
    'pin-card': pinCard,
    'full-card': fullListingCard
  }"
  (mouseenter)="fullListingService.selectListing(listing, provider!, fullListingCard)"
>
  <div class="imgs" *ngIf="listing.media && listing.media.photos && listing.media.photos.length > 0; else noImage">
    <button *ngIf="!forSale && listing.media.photos.length > 0" class="view-gallery focus-link" (click)="viewGallery()">
      <img src="assets/icons/picture-double.svg" alt="View gallery" />
      View Gallery
    </button>

    <!-- <button
      *ngIf="!forSale"
      class="favorite focus-link"
      (click)="favoriteItem()"
      [ngClass]="{ saved: savedCard, updating: updating }"
    >
      <img *ngIf="!savedCard" src="assets/icons/favorite.svg" alt="Favorite home" />
      <img *ngIf="savedCard" src="assets/icons/favorite-white.svg" alt="Un-favorite home" />
    </button> -->
    <hch-favorite-icon
      [listing]="listing"
      [savedCard]="savedCard"
      [provider]="provider"
      display="ICON"
    ></hch-favorite-icon>
    <carousel [isAnimated]="true">
      <slide *ngFor="let slide of listing.media.photos.slice(0, 4)">
        <img [defaultImage]="defaultImg" [lazyLoad]="slide" class="gallery" />
      </slide>
    </carousel>
  </div>
  <ng-template #noImage>
    <div class="no-image">
      <img alt="" [src]="defaultImg" />
      <p class="no-image-text">No Images Available</p>
    </div>
  </ng-template>
  <div class="contents">
    <div class="price">{{ formattedPrice }}</div>
    <div class="categories">
      <div>{{ listing.bedrooms || 'N/A' }} bd</div>
      <div>{{ listing.bathrooms || 'N/A' }} ba</div>
      <div>
        {{
          listing.squareFeet
            ? listing.squareFeet + ' sqft'
            : listing.lotSizeArea
            ? listing.lotSizeArea + ' ' + listing.lotSizeUnits?.toLowerCase()
            : 'N/A'
        }}
      </div>
    </div>
    <div class="address">
      <div>{{ listing.address || 'N/A' }},</div>
      <div>
        {{ listing.city || 'N/A' }}, {{ listing.state || 'N/A' }}
        {{ listing.zipcode || 'N/A' }}
      </div>
    </div>
    <div class="status" *ngIf="!forSale">
      <div>
        <span>Status: {{ ' ' }}</span>
        <span class="active">{{ listing.standardStatus || 'N/A' }}</span>
      </div>
      <div class="time-ago" *ngIf="listing.modificationTimestamp">
        <img src="assets/icons/stop-watch.svg" alt="new" />
        <span>
          Added to HomeCaptain
          {{ listing.modificationTimestamp | timeago }} ago
        </span>
      </div>
      <a [href]="listing.virtualTour" class="virtual-tour" *ngIf="listing.virtualTour">
        <img src="assets/icons/virtual.svg" alt="virtual" />
        Virtual Tour
      </a>
    </div>
    <a
      class="see-full-listing"
      [routerLink]="routingService.getFullListingURL(listing.listingId!, provider!)"
      [queryParams]="routingService.staticQueryParams | async"
      (click)="incrementViewCount($event)"
    >
      See Full Listing
      <img src="assets/icons/chevron-right-blue.svg" alt="Right button" />
    </a>
    <div class="mls-listing">
      <div *ngIf="listing.mlsLogo" class="mls-icon">
        <img [src]="getMLSLogo(listing.mlsLogo)" alt="MLS Icon" />
      </div>
      <div class="summary">
        <p style="margin-bottom: 8px">MLS #: {{ listing.mlsId || 'N/A' }}</p>
        <div>
          <p class="provided-by">
            Listing provided by
            {{ listing.agentFullName || 'N/A' }}
            <ng-container *ngIf="listing.agentOfficeName">of {{ listing.agentOfficeName || 'N/A' }}</ng-container>
          </p>
          <p *ngIf="listing.agentPreferredPhone">Agent Phone: {{ (listing.agentPreferredPhone | phone) || 'N/A' }}</p>
          <p *ngIf="!listing.agentPreferredPhone && listing.agentOfficePhone">
            Office Phone: {{ (listing.agentOfficePhone | phone) || 'N/A' }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="home-card" *ngIf="loading">
  <div class="imgs">
    <ngx-skeleton-loader animation="progress"></ngx-skeleton-loader>
  </div>
  <div class="contents">
    <div class="price">
      <ngx-skeleton-loader animation="progress"></ngx-skeleton-loader>
    </div>
    <div class="categories">
      <ngx-skeleton-loader animation="progress"></ngx-skeleton-loader>
    </div>
    <div class="address">
      <ngx-skeleton-loader animation="progress"></ngx-skeleton-loader>
    </div>
    <div class="status">
      <ngx-skeleton-loader animation="progress"></ngx-skeleton-loader>
    </div>
    <div class="see-full-listing">
      <ngx-skeleton-loader animation="progress"></ngx-skeleton-loader>
    </div>
    <div class="mls-icon">
      <ngx-skeleton-loader animation="progress"></ngx-skeleton-loader>
    </div>
  </div>
</div>
