import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError, of, switchMap, throwError } from 'rxjs';

import { OnExecuteData, OnExecuteErrorData, RecaptchaErrorParameters, ReCaptchaV3Service } from 'ng-recaptcha';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { environment } from 'src/environments/environment';
import { AuthException } from 'src/app/core/enums/auth.enum';
import { AuthService } from 'src/app/core/services/auth.service';

import { FormValidationService } from 'src/app/core/services/form-validation.service';
import { SearchService } from 'src/app/core/services/search.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { Unsubscriber } from 'src/app/core/extenders/unsubscriber';
import { RoutingService } from 'src/app/_site-specific/services/routing/routing.service';
import { LoanOfficerService } from 'src/app/core/services/loan-officer.service';

@Component({
  selector: 'hch-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent extends Unsubscriber implements OnInit {
  step = 0;

  registerForm: FormGroup;
  finalForm: FormGroup;
  verifyForm: FormGroup;

  submitted = false;
  showPassword = false;
  modalRef?: BsModalRef;

  baseUrl = environment.AUTHAPI_BASE_URL;
  // mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  authErrMsg = '';
  // resendingCode = 'Resend';
  // isRobot = true;
  today: string = '';

  // public recaptchaToken = '';
  // recaptchaSubscription = null;
  // public recentError?: { error: any };
  // public readonly executionLog: Array<OnExecuteData | OnExecuteErrorData> = [];

  @ViewChild('signin') signInLink?: ElementRef;

  // @Input() status: string = '';
  @Input() title: string = 'Create Your Free Account';
  // @Output() closeEvent = new EventEmitter();
  // @Output() changeCategory = new EventEmitter();

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') this.close();
  }

  constructor(
    private authService: AuthService,
    private bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private formValidationService: FormValidationService,
    private loanOfficerService: LoanOfficerService,
    private modalService: ModalService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private routingService: RoutingService,
    private searchService: SearchService
  ) {
    super();

    this.registerForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, this.formValidationService.emailValidator()])],
      // password: ['', Validators.required],
    });

    this.finalForm = this.fb.group({
      firstName: ['', Validators.compose([Validators.required, this.formValidationService.nameValidator()])],
      lastName: ['', Validators.compose([Validators.required, this.formValidationService.nameValidator()])],
      phone: ['', Validators.compose([Validators.required, this.formValidationService.phoneNumberValidator()])],
    });

    this.verifyForm = this.fb.group({
      code: ['', Validators.required],
    });

    const date = new Date();
    const month = date.toLocaleString('default', { month: 'long' });
    this.today = `${month} ${date.getDate()}, ${date.getFullYear()}`;
  }

  ngOnInit(): void {}

  // resolved(captchaResponse: string) {
  //   this.isRobot = false;
  //   this.recaptchaToken = captchaResponse;
  // }
  // public onError(errorDetails: RecaptchaErrorParameters): void {
  //   this.isRobot = true;
  // }

  get email() {
    return this.registerForm.get('email');
  }
  get phone() {
    return this.finalForm.get('phone');
  }
  get firstName() {
    return this.finalForm.get('firstName');
  }
  get lastName() {
    return this.finalForm.get('lastName');
  }
  get code() {
    return this.verifyForm.get('code');
  }

  close() {
    // this.closeEvent.emit();
    this.bsModalRef.hide();
    if (this.authService.authorized.getValue()) {
      document.getElementById('hamburger-icon')?.focus();
    } else {
      document.getElementById('register')?.focus();
    }
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  login() {
    this.bsModalRef.hide();
    this.modalService.showLoginModal();
    // this.changeCategory.emit(AuthModalCategory.LOGIN);
  }

  // checkIfUserExists() {
  //   this.addSubscription = this.recaptchaV3Service.execute('signupAction').subscribe((token) => {
  //     this.submitted = true;
  //     this.authErrMsg = '';

  //     try {
  //       this.authService.userExist(this.registerForm.value.email, token).subscribe((response: any) => {
  //         if (response.isExist) {
  //           this.authErrMsg = 'Email Already in use, please sign in';
  //           this.signInLink.nativeElement.focus();
  //         } else {
  //           this.step = 1;
  //         }
  //         this.submitted = false;
  //       });
  //     } catch (error: any) {
  //       this.submitted = false;
  //       if (error?.email?.msg === AuthException.EMAIL_WRONG_FORMAT) {
  //         this.authErrMsg = 'Email is in wrong format';
  //       } else {
  //         this.authErrMsg = 'Unexpected Error.';
  //       }
  //     }
  //   });
  // }

  checkIfUserExists() {
    this.addSubscription = this.recaptchaV3Service
      .execute('signupAction')
      .pipe(
        switchMap((token) => {
          this.submitted = true;
          this.authErrMsg = '';
          return this.authService.userExist(this.registerForm.value.email, token);
        }),
        switchMap((response: any) => {
          if (response.isExist) {
            this.authErrMsg = 'Email Already in use, please sign in';
            this.signInLink?.nativeElement.focus();
          } else {
            this.step = 1;
          }
          this.submitted = false;
          return of(null);
        }),
        catchError((error) => {
          this.submitted = false;
          if (error?.email?.msg === AuthException.EMAIL_WRONG_FORMAT) {
            this.authErrMsg = 'Email is in wrong format';
          } else {
            this.authErrMsg = 'Unexpected error';
            return throwError(() => error);
          }
          return of(null);
        })
      )
      .subscribe();
  }

  /**
   * Handles logic to register a user in these cases:
   * 1. If Citi MR info is provided (nmls, id, and campaign id)
   * 2. Everything else
   */
  private registerUser(input: { email: string; phoneNumber: string; fullName: string; password: string }) {
    const { email, phoneNumber, fullName, password } = input;
    const nmls = this.routingService.nmls.getValue();
    if (nmls) {
      const staticQueryParams = this.routingService.staticQueryParams.getValue();
      if (staticQueryParams['id'] && staticQueryParams['campaignid']) {
        return of(null).pipe(
          switchMap(() => this.loanOfficerService.getLoanOfficerId(nmls)),
          switchMap((data) => {
            if (data && data.email && data.name) {
              return this.authService.registerUserMR({
                email,
                phoneNumber,
                password,
                fullName,
                nmls,
                loanOfficerEmail: data.email,
                loanOfficerName: data.name,
                id: staticQueryParams['id'],
                campaignid: staticQueryParams['campaignid'],
              });
            }
            return throwError(() => 'Unable to get loan officer from database with id ' + nmls);
          }),
          catchError((error) => {
            this.authErrMsg = 'Error registering new user';
            return throwError(() => error);
          })
        );
      }
    }
    return this.authService.registerUser({ email, phoneNumber, fullName, password });
  }

  register() {
    this.submitted = true;
    this.authErrMsg = '';
    const p = this.finalForm.value.phone.replace(/[^0-9]/g, '');
    const phoneNumber = '(' + p.slice(0, 3) + ') ' + p.slice(3, 6) + '-' + p.slice(6, 10);
    const fullName = this.firstName?.value + ' ' + this.lastName?.value;
    const email = this.registerForm.value.email;

    // if (!this.status) {
    this.addSubscription = of(null)
      .pipe(
        switchMap(() => {
          return this.registerUser({
            email,
            phoneNumber,
            fullName,
            password: p,
          });
        }),
        switchMap(() => this.recaptchaV3Service.execute('signupAction')),
        switchMap((token) => this.authService.signInWithEmail({ email, password: p, token })),
        // switchMap(() => this.searchService.loadTempData()),
        switchMap(() => {
          this.submitted = false;
          this.close();
          return of(null);
        }),
        catchError((error) => {
          this.authErrMsg = 'Error registering new user';
          this.submitted = false;
          return throwError(() => error);
        })
      )
      .subscribe();
    // } else {
    //   this.addSubscription = of(null)
    //     .pipe(
    //       switchMap(() => this.authService.updateUserInfo(fullName, rawPhone)),
    //       // switchMap(() => this.searchService.loadTempData()),
    //       switchMap(() => {
    //         this.close();
    //         return of(null);
    //       }),
    //       catchError((error) => {
    //         console.error('ERROR updating user info:', error);
    //         this.authErrMsg = error.server?.msg || 'Error updating user info';
    //         this.submitted = false;
    //         return throwError(() => error);
    //       })
    //     )
    //     .subscribe();
    // }
  }

  // async resendCode() {
  //   try {
  //     this.resendingCode = 'Sending';
  //     await this.authService.resendVerificationCode(this.registerForm.value.email);
  //     this.resendingCode = 'Sent';
  //     setTimeout(() => {
  //       this.resendingCode = 'Resend';
  //     }, 3000);
  //   } catch (err) {
  //     this.resendingCode = 'Resend';
  //   }
  // }

  // async verifyCode() {
  //   this.submitted = true;
  //   this.authErrMsg = '';

  //   try {
  //     let data = await this.authService.verifyEmail(this.registerForm.value.email, this.verifyForm.value.code);
  //     let password = this.finalForm.value.phone.replace(/[^0-9]/g, '');
  //     await this.authService.signInWithEmail(this.registerForm.value.email, password);
  //     this.submitted = false;
  //     this.close();
  //   } catch (err) {
  //     this.authErrMsg = 'The verification code is invalid';
  //     this.submitted = false;
  //   }
  // }

  googleLoginURL() {
    return `${this.baseUrl}/google-login?callback=${btoa(window.location.href)}`;
  }

  facebookLoginURL() {
    return `${this.baseUrl}/facebook-login?callback=${btoa(window.location.href)}`;
  }

  openTermsConditionsModal() {
    this.modalRef = this.modalService.showTermsConditionsModal();
    this.addSubscription = this.modalRef.onHidden?.subscribe(() => {
      setTimeout(() => {
        document.getElementById('tos-sign-up-btn')?.focus();
      }, 0);
    });
  }

  openPrivacyPolicyModal() {
    this.modalRef = this.modalService.showPrivacyPolicyModal();
    this.addSubscription = this.modalRef.onHidden?.subscribe(() => {
      setTimeout(() => {
        document.getElementById('pp-sign-up-btn')?.focus();
      }, 0);
    });
  }

  openPoliciesModal() {
    this.modalRef = this.modalService.showPoliciesModal();
    this.addSubscription = this.modalRef.onHidden?.subscribe(() => {
      setTimeout(() => {
        document.getElementById('policy-sign-up-btn')?.focus();
      }, 0);
    });
  }
}
