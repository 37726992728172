import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Params } from '@angular/router';
import { BehaviorSubject, map, of, switchMap } from 'rxjs';

import { Unsubscriber } from '../core/extenders/unsubscriber';
import { toCapitalCase } from '../core/helpers';
import { Listing } from '../core/models/listing.model';
import { DataTreeApiService } from '../core/services/data-tree-api.service';
import { RoutingService } from '../_site-specific/services/routing/routing.service';
import { zipCode } from '../shared/pipes/zipcode.pipe';
import { environment } from 'src/environments/environment';

import { DataTreeAVM } from './models/dataTreeAWM.model';
import { MarketStatisticsReport } from './models/MarketStatisticsReport';
import { PropertyDetailReport } from './models/PropertyDetailReport.model';
import { TotalViewReport } from './models/TotalViewReport.model';
import { AuthService } from '../core/services/auth.service';

export type HomeValueCategory = 'selling' | 'refinancing' | 'valuation' | undefined;

export interface Address {
  address?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  zipCode4?: string;
}

@Injectable({
  providedIn: 'root',
})
export class HomeValueService extends Unsubscriber {
  category = new BehaviorSubject<HomeValueCategory>(undefined);
  address = new BehaviorSubject<Address>({});

  reportNotFound = new BehaviorSubject<boolean>(false);

  loading = new BehaviorSubject<boolean>(false);
  loadingTrends = new BehaviorSubject<boolean>(false);
  loadingHomeValue = new BehaviorSubject<boolean>(false);
  loadingNearbyListings = new BehaviorSubject<boolean>(false);

  searchOptions_dataTreeAVM = new BehaviorSubject<any | undefined>(undefined);
  searchOptions_marketStatisticsReport = new BehaviorSubject<any | undefined>(undefined);

  dataTreeAVM = new BehaviorSubject<DataTreeAVM | undefined>(undefined);
  propertyListingReport = new BehaviorSubject<any | undefined>(undefined);
  totalViewReport = new BehaviorSubject<TotalViewReport | undefined>(undefined);
  propertyDetailReport = new BehaviorSubject<PropertyDetailReport | undefined>(undefined);
  marketStatisticsReport = new BehaviorSubject<MarketStatisticsReport | undefined>(undefined);

  listingsForSale = new BehaviorSubject<Listing[]>([]);
  listingsRecentlySold = new BehaviorSubject<Listing[]>([]);

  constructor(
    private authService: AuthService,
    private dataTreeApiService: DataTreeApiService,
    private http: HttpClient,
    private routingService: RoutingService
  ) {
    super();

    /**
     * Watches for changes on the query params stored in the routing service
     * On change, set search options
     * Searches are conducted in parallel in separate subscriptions when the search options change
     */
    this.addSubscription = this.routingService.homeValueQueryParams
      .pipe(
        map((params) => {
          if (!params || Object.keys(params).length == 0) return;

          this.reportNotFound.next(false);
          this.loadingTrends.next(true);
          this.loadingHomeValue.next(true);
          this.loadingNearbyListings.next(true);

          const address = toCapitalCase(params['Address']);
          const city = toCapitalCase(params['City']);
          const state = params['State'];
          const zip = zipCode(params['Zip']);

          this.address.next({
            address,
            city,
            state,
            zipCode: zip,
            zipCode4: params['ZipCode4'],
          });

          const searchOptions_dataTreeAVM = {
            ReferenceId: 'homecaptain',
            ProductNames: ['DataTreeAVM', 'PropertyListingReport', 'TotalViewReport', 'PropertyDetailReport'],
            ResponseType: 'Advanced',
            SearchType: 'FullAddress',
            FullAddress:
              (address ? address + ' ' : '') + (city ? city + ' ' : '') + (state ? state + ' ' : '') + (zip ? zip : ''),
          };

          const searchOptions_marketStatisticsReport = {
            ReferenceId: 'homecaptain',
            ProductNames: ['MarketStatisticsReport'],
            MaxReturn: 10000,
            Filters: [
              {
                FilterName: 'ZipCodeRange',
                FilterOperator: 'is',
                FilterValues: [zip],
                FilterGroup: 0,
              },
            ],
          };

          this.searchOptions_dataTreeAVM.next(searchOptions_dataTreeAVM);
          this.searchOptions_marketStatisticsReport.next(searchOptions_marketStatisticsReport);
        })
      )
      .subscribe();

    /**
     * Watches for changes to the search options
     * On change, makes API call to get report
     * Loads home value report into local subjects
     */
    this.addSubscription = this.searchOptions_dataTreeAVM
      .pipe(
        switchMap((value) => {
          if (!value) return of(null);
          return this.dataTreeApiService.getReport(value);
        }),
        map((data: any) => {
          if (!data) return;
          if (data.Reports) {
            this.dataTreeAVM.next(data.Reports[0].Data);
            this.propertyListingReport.next(data.Reports[1].Data);
            this.totalViewReport.next(data.Reports[2].Data);
            this.propertyDetailReport.next(data.Reports[3].Data);
          }
          this.loadingHomeValue.next(false);
          if (data.Reports[0].Message === 'The requested report is not available for this property.') {
            this.reportNotFound.next(true);
            this.stopLoading();
            return;
          }
        })
      )
      .subscribe();

    /**
     * Watches for changes to the market search options
     * On change, makes API call to get report
     * Sends a home value email to user's email address
     */
    this.addSubscription = this.searchOptions_marketStatisticsReport
      .pipe(
        switchMap((value) => {
          if (!value) return of(null);
          return this.dataTreeApiService.getMultiLineReport(value);
        }),
        switchMap((data: any) => {
          if (!data) return of(null);
          this.marketStatisticsReport.next(data.Reports[0].Data);

          const dataTreeAVM = this.dataTreeAVM.getValue();
          if (!dataTreeAVM) return of(null);

          const curVal = dataTreeAVM.ValuationSummary.EstimatedValue;
          const oldVal = dataTreeAVM.HomeTrendData.FiveYearsMedianTrendDetail.AVMValue[0] * 1000;
          const min = Math.min(curVal, oldVal);
          const max = Math.max(curVal, oldVal);
          const increase = Math.round(((max - min) / oldVal) * 10000) / 100;

          const params = this.routingService.homeValueQueryParams.getValue();
          const state = params['State'] as string;
          const city = params['City'] as string;
          const zip = params['ZipCode'] as string;

          const address = `${dataTreeAVM.SubjectProperty.SitusAddress.StreetAddress.toLowerCase()}, ${city.toLowerCase()}, ${state} ${zip}`;

          this.loadingTrends.next(false);

          const user = this.authService.currentUser.getValue();

          // return of(null);
          return this.sendHomeValueEmail({
            lead_first_name: user?.fullName,
            email: user?.email,
            low_value: '$' + this.priceString(dataTreeAVM.ValuationSummary.EstimatedValueLow),
            mid_value: '$' + this.priceString(dataTreeAVM.ValuationSummary.EstimatedValue),
            high_value: '$' + this.priceString(dataTreeAVM.ValuationSummary.EstimatedValueHigh),
            address,
            increased: curVal > oldVal ? 'increased' : 'decreased',
            increase_value: increase,
            reportUrl: window.location.href,
          });
        })
      )
      .subscribe();

    /**
     * Watches for changes to the total view report
     * On changes, sets the nearby listings for sale and listings recently sold
     */
    this.addSubscription = this.totalViewReport
      .pipe(
        // Get the nearby listings recently sold
        map((value) => {
          if (!value) return;

          // this.listingsForSale.next([]);
          // let listingsForSale = [];
          // for (const property of value.NearByListingData) {
          //   if (property.ListingStatus == 'Active') {
          //     listingsForSale.push(this.dataTreeApiService.getDataFromDataTreeListing(property));
          //   }
          // }
          // this.listingsForSale.next(listingsForSale);

          this.listingsRecentlySold.next([]);
          let listingsRecentlySold = [];
          for (const property of value.NearBySalesData) {
            listingsRecentlySold.push(this.dataTreeApiService.getDataFromDataTreeListing(property));
          }
          this.listingsRecentlySold.next(listingsRecentlySold);
        })
        // Get the nearby listings for sale from Listhub or Realstaq
        // switchMap(() => {

        // }),
        // Process response from API
        // map((res) => {

        // })
      )
      .subscribe();
  }

  /**
   * Handles the bulk of the logic to load the Home Valuation page and reports.
   * On query parameter change, the reports are fetched in parallel.
   * A user must be logged in for the /category and /estimation page to load.
   */
  initialize(params: Params) {
    if (Object.keys(params).length == 0) {
      return;
    }
    if (!params['Address'] || !params['City'] || !params['State'] || !params['Zip']) {
      return;
    }

    const existingParams = this.routingService.homeValueQueryParams.getValue();
    if (JSON.stringify(existingParams) != JSON.stringify(params)) {
      this.loading.next(true);
      this.routingService.homeValueQueryParams.next(params);
    }
  }

  /**
   * Triggers an email to be sent to the user upon visiting the Home Value page.
   * @param data Post body payload
   * @returns An observable with the Http request
   */
  sendHomeValueEmail(data: any) {
    return this.http.post(environment.HUBAPI_BASE_URL + '/home-value-email', {
      email: data.email,
      homeValueInfo: data || {},
    });
  }

  // get categoryType() {
  //   const single = this.categories.filter((c) => c === true).length === 1;
  //   if (single) {
  //     if (this.categories[0]) return 0;
  //     if (this.categories[1]) return 1;
  //   }
  //   return 2;
  // }

  priceString(value: number | string) {
    if (typeof value == 'string') return Number(value).toLocaleString('en-US');
    return value.toLocaleString('en-US');
  }

  stopLoading() {
    this.loading.next(false);
    this.loadingTrends.next(false);
    this.loadingHomeValue.next(false);
    this.loadingNearbyListings.next(false);
  }

  get fiveYearsAgoPrice() {
    const dataTreeAVM = this.dataTreeAVM.getValue();
    if (!dataTreeAVM) return 'N/A';

    const curVal = dataTreeAVM.ValuationSummary.EstimatedValue;
    const oldVal = dataTreeAVM.HomeTrendData.FiveYearsMedianTrendDetail.AVMValue[0] * 1000;

    // Return 100% if oldValue does not exist i.e. it's a new-ish property
    if (oldVal === 0) return '+$' + this.priceString(curVal) + ' (+100%)';
    const min = Math.min(curVal, oldVal);
    const max = Math.max(curVal, oldVal);
    const sign = curVal > oldVal ? '+' : '-';
    return (
      sign + '$' + this.priceString(max - min) + ' (' + sign + Math.round(((max - min) / oldVal) * 10000) / 100 + '%)'
    );
  }

  get propertyDetailReportValue() {
    return this.propertyDetailReport.getValue();
  }
  get totalViewReportValue() {
    return this.totalViewReport.getValue();
  }
  get propertyListingReportValue() {
    return this.propertyListingReport.getValue();
  }

  get houseNumber() {
    let houseNumber = this.propertyDetailReportValue?.SubjectProperty?.ParsedStreetAddress?.StandardizedHouseNumber;
    if (houseNumber && houseNumber % 2 == 0) return 0;
    else return 180;
  }

  get latitude() {
    let latitude = this.propertyDetailReportValue?.LocationInformation?.Latitude;
    if (latitude) return latitude;
    return 0;
  }

  get longitude() {
    let longitude = this.propertyDetailReportValue?.LocationInformation?.Longitude;
    if (longitude) return longitude;
    return 0;
  }

  get bedrooms() {
    let beds = this.propertyDetailReportValue?.PropertyCharacteristics?.Bedrooms || 0;
    if (!beds) {
      beds = this.totalViewReportValue?.PropertyDetailData?.PropertyCharacteristics?.Bedrooms || 0;
    }
    if (!beds) {
      beds = this.propertyListingReportValue?.ListingPropertyDetail?.Bedrooms || 0;
    }
    return Number(beds);
  }

  get bathrooms() {
    let baths = this.propertyDetailReportValue?.PropertyCharacteristics?.FullBath || 0;
    if (!baths) {
      baths = this.totalViewReportValue?.PropertyDetailData?.PropertyCharacteristics?.Bathrooms || 0;
    }
    if (!baths) {
      baths = this.propertyListingReportValue?.ListingPropertyDetail?.Bathrooms || 0;
    }
    return Number(baths);
  }

  get sqFt() {
    let squareFeet = this.propertyDetailReportValue?.SiteInformation?.LivingArea || 0;
    if (!squareFeet) {
      squareFeet = this.totalViewReportValue?.PropertyDetailData?.PropertyCharacteristics?.LivingArea || 0;
    }
    if (!squareFeet) {
      squareFeet = this.propertyListingReportValue?.ListingPropertyDetail?.LivingArea || 0;
    }
    return squareFeet;
  }

  get lotSizeSqFt() {
    let lotSize = this.propertyDetailReportValue?.SiteInformation?.LotArea || 0;
    if (!lotSize) {
      lotSize = this.totalViewReportValue?.PropertyDetailData?.PropertyCharacteristics?.LotSizeSqFt || 0;
    }
    if (!lotSize) {
      lotSize = this.propertyListingReportValue?.ListingPropertyDetail?.LotSizeSqFt || 0;
    }
    return lotSize;
  }
}
