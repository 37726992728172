import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, of, switchMap } from 'rxjs';

import { debounce } from '../core/directives/debounce.decorator';

import { Listing, Provider, ProviderE } from '../core/models/listing.model';
import { FooterService } from '../core/services/footer.service';
import { SearchService } from '../core/services/search.service';

// type ObservableInputReturn<O> = O extends ObservableInput<infer T> ? T : never;
//   export function unionMap<T, O extends ObservableInput<any>>(
//     project: (value: T, index: number) => O
//   ): OperatorFunction<T, ObservableInputReturn<O>> {
//     return (source: Observable<T>) => new Observable<ObservableInputReturn<O>>();
//   }

@Injectable({
  providedIn: 'root',
})
export class FullListingService {
  listing = new BehaviorSubject<Listing | undefined>(undefined);
  similarListings = new BehaviorSubject<Listing[] | undefined>(undefined);
  provider = new BehaviorSubject<Provider>(ProviderE.LISTHUB);
  loading = new BehaviorSubject<boolean>(true);

  constructor(private footerService: FooterService, private searchService: SearchService) {}

  /**
   * Enables the full listing page to load without hitting the API database
   * when the user hovers over a listing card. This listing is saved in
   * this service's local state and loaded if exists.
   * @param listing
   */
  @debounce()
  selectListing(listing: Listing, provider: Provider, fullListingCard: boolean) {
    if (!fullListingCard) {
      this.listing.next(listing);
      this.provider.next(provider);
    }
  }

  /**
   * Retrieves selected listing from API if no listing is stored in state
   * @param id
   */
  loadSelectedListing(id: string, provider: Provider) {
    this.loading.next(true);
    return this.searchService.getListingById(id, provider).pipe(
      switchMap((listing) => {
        if (listing) {
          this.listing.next(listing);
          this.provider.next(provider);
          this.loadFooter();
          this.loading.next(false);
          return of(listing);
        }
        return of(null);
      })
    );
  }

  /**
   * Sets the mls footer info
   */
  loadFooter() {
    const listing = this.listing.getValue();
    if (listing) {
      if (listing.mlsDisclaimer) {
        this.footerService.setMlsInfo(listing.mlsDisclaimer);
      } else {
        this.footerService.setMlsInfo('');
      }
      if (listing.mlsLogo) {
        this.footerService.setMlsIcon(listing.mlsLogo);
      } else {
        this.footerService.setMlsIcon('');
      }
    }
  }

  /**
   * Loads similar listings given a listing
   */
  loadSimilarListings() {
    return combineLatest([this.listing, this.provider]).pipe(
      switchMap(([listing, provider]) => {
        if (listing && provider) {
          if (provider == ProviderE.LISTHUB) return this.searchService.getSimilarListingsByIdListHub(listing);
          if (provider == ProviderE.REALSTAQ)
            return this.searchService.getSimilarListingsByIdRealStaq(listing.listingId!);
        }
        return of(null);
      }),
      switchMap((result) => {
        if (result) {
          this.similarListings.next(result.listings);
          return of(result.listings);
        }
        return of(null);
      })
    );
  }
}
