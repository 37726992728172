<div cdkTrapFocus>
  <div class="gallery-actions">
    <a
      class="see-full-listing"
      [routerLink]="routingService.getFullListingURL(listing.listingId!, provider)"
      [queryParams]="routingService.staticQueryParams | async"
      (click)="bsModalRef.hide()"
    >
      <!-- (click)="incrementViewCount($event)" -->
      See Full Listing
      <img src="assets/icons/chevron-right-blue.svg" alt="Right button" />
    </a>
    <button class="hch-btn-link gallery-close-btn" (click)="bsModalRef.hide()">
      <img src="assets/images/icons/close.svg" alt="Close button" />
    </button>
  </div>
  <div class="gallery-info">
    <section class="gallery-details">
      <div style="margin-right: 1rem">
        <h2 style="margin-bottom: 1rem">{{ formattedPrice }}</h2>
        <h3 style="font-size: 1.5rem; margin: 0">{{ listing.address }}</h3>
        <h3 style="font-size: 1.25rem; margin-bottom: 1rem">
          {{ listing.city || 'N/A' }}, {{ listing.state || 'N/A' }}
          {{ listing.zipcode || 'N/A' }}
        </h3>
        <div style="display: flex; gap: 1rem; margin-bottom: 1rem">
          <span>{{ listing.bedrooms || 'N/A' }} bd</span>
          <span style="opacity: 0.75">|</span>
          <span>{{ listing.bathrooms || 'N/A' }} ba</span>
          <span style="opacity: 0.75">|</span>
          <span>
            {{
              listing.squareFeet
                ? listing.squareFeet + ' sqft'
                : listing.lotSizeArea
                ? listing.lotSizeArea + ' ' + listing.lotSizeUnits?.toLowerCase()
                : 'N/A'
            }}
          </span>
        </div>
        <div style="display: flex; align-items: center; margin: 1rem 0">
          <p style="margin: 0">
            <span>Status: {{ ' ' }}</span>
            <span class="active">{{ listing.standardStatus || 'N/A' }}</span>
          </p>
          <div class="time-ago" *ngIf="listing.modificationTimestamp">
            <img src="assets/icons/stop-watch.svg" alt="new" />
            <span>
              Added to HomeCaptain
              {{ listing.modificationTimestamp | timeago }} ago
            </span>
          </div>
        </div>
      </div>
      <!-- <aside style="flex: 1">
        <div class="tab-container">
          <button
            class="tab"
            [ngClass]="{ 'tab-selected': viewMode == 'contact-info' }"
            (click)="viewMode = 'contact-info'"
          >
            Contact Info
          </button>
          <button
            class="tab"
            [ngClass]="{ 'tab-selected': viewMode == 'mortgage-calculator' }"
            (click)="viewMode = 'mortgage-calculator'"
          >
            Mortgage Calculator
          </button>
        </div>
        <div *ngIf="viewMode == 'contact-info'" class="card" style="padding: 1rem"> -->
      <div>
        <div *ngIf="listing.mlsLogo">
          <!-- <img [src]="listing.mlsLogo" alt="MLS Icon" /> -->
          <img [src]="getMLSLogo(listing.mlsLogo)" alt="MLS Icon" style="max-width: 8rem" />
        </div>
        <p style="margin: 0.5rem 0">MLS #: {{ listing.mlsId || 'N/A' }}</p>
        <div>
          <p style="margin: 0 0 1rem 0">
            Listing provided by
            {{ listing.agentFullName || 'N/A' }}
            <ng-container *ngIf="listing.agentOfficeName">of {{ listing.agentOfficeName || 'N/A' }}</ng-container>
          </p>
          <div *ngIf="listing.agentPreferredPhone">
            <p style="margin: 0">{{ (listing.agentPreferredPhone | phone) || 'N/A' }}</p>
            <p style="font-size: 0.75rem">Agent Phone</p>
          </div>
          <div *ngIf="!listing.agentPreferredPhone && listing.agentOfficePhone">
            <p style="margin: 0">{{ (listing.agentOfficePhone | phone) || 'N/A' }}</p>
            <p style="font-size: 0.75rem">Agent Office Phone</p>
          </div>
          <div *ngIf="listing.mlsBrokerPhone">
            <p style="margin: 0">{{ (listing.mlsBrokerPhone | phone) || 'N/A' }}</p>
            <p style="font-size: 0.75rem">Brokerage Office Phone</p>
          </div>
        </div>
      </div>
      <!-- </div>
        <div *ngIf="viewMode == 'mortgage-calculator'">
          <hch-payment-calculator type="full"></hch-payment-calculator>
        </div> -->
      <!-- </aside> -->
    </section>

    <figure *ngFor="let photo of listing.media!.photos; index as i" class="gallery-figure">
      <img [src]="photo" [alt]="listing.address + ' photo ' + i" class="gallery-img" />
    </figure>
  </div>
</div>
