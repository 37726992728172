import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { map, finalize, of, Subject, switchMap } from 'rxjs';

import { Listing, Provider } from 'src/app/core/models/listing.model';
// import { MLSPROVIDERS } from 'src/environments/environment';

import { ActivityService } from 'src/app/core/services/activity.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { FooterService } from 'src/app/core/services/footer.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { RoutingService } from 'src/app/_site-specific/services/routing/routing.service';
// import { SessionService } from 'src/app/core/services/session.service';
import { StatsService } from 'src/app/core/services/stats.service';
import { StaticMapService } from 'src/app/core/services/static-map.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { FullListingService } from 'src/app/full-listing/full-listing.service';
import { Unsubscriber } from 'src/app/core/extenders/unsubscriber';
// import { GalleryModalComponent } from '../../modals/gallery-modal/gallery-modal.component';

@Component({
  selector: 'hch-listing-card',
  templateUrl: './listing-card.component.html',
  styleUrls: ['./listing-card.component.scss'],
})
export class ListingCardComponent extends Unsubscriber implements OnInit {
  @Input() componentId = '';
  @Input() mapCard = false;
  @Input() savedCard = false;
  @Input() forSale = false;
  @Input() pinCard = false;
  @Input() fullListingCard = false;
  @Input() provider: Provider | undefined = '';
  @Input() loading: boolean = false;

  @Input()
  set listing(listing: Listing | undefined) {
    if (listing && listing.price && !isNaN(Number(listing.price))) {
      this.formattedPrice = '$' + Number(listing.price).toLocaleString();
    } else {
      this.formattedPrice = 'N/A';
    }
    this._listing = listing;
  }
  get listing() {
    return this._listing;
  }
  private _listing: Listing | undefined;

  // savedCard = false;

  imageObject = [
    { thumbImage: 'assets/images/buy/pixelate.png' },
    { thumbImage: 'assets/images/buy/pixelate.png' },
    { thumbImage: 'assets/images/buy/pixelate.png' },
    { thumbImage: 'assets/images/buy/pixelate.png' },
  ];
  focusedGallery = 0;

  defaultImg = 'assets/images/buy/no-image.png';
  // scroll$;
  isScrolling = false;
  updating = false;
  // queryParams;
  // isMRTemplate: boolean;
  // nmls: string;
  // fullListingLink: string;
  formattedPrice: string = '';
  displayNoImage = false;
  googleImage = false;

  // userSubscription: Subscription;
  // currentUser;
  // providerId;

  slideConfig = {
    arrows: true,
    dots: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    infinite: false,
  };

  // modalRef: BsModalRef;
  // authModal: BsModalRef;

  // @ViewChild('slickModal') slickModal: SlickCarouselComponent;

  carouselSubject = new Subject();

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private activityService: ActivityService,
    private footerService: FooterService,
    public fullListingService: FullListingService,
    private modalService: ModalService,
    public routingService: RoutingService,
    // private sessionService: SessionService,
    public staticMapService: StaticMapService,
    private statsService: StatsService,
    private storageService: LocalStorageService
  ) {
    super();
  }

  ngOnInit(): void {
    // this.route.queryParams.subscribe((params) => {
    //   this.queryParams = params;
    // });
  }

  // get price() {
  //   return Number(this.listing.price).toLocaleString('en-US');
  // }

  get listingId() {
    let lId = 'listing-slide-' + this.listing?.mlsId;
    if (this.savedCard) lId += '-saved-card';
    if (this.mapCard) lId += '-map-card';
    if (this.forSale) lId += '-for-sale';
    if (this.pinCard) lId += '-pin-card';
    if (this.fullListingCard) lId += '-full-card';
    return lId;
  }

  getMLSLogo(url: string) {
    return this.footerService.getIconPathFromUrl(url);
  }

  viewGallery() {
    if (this.listing && this.provider) {
      this.modalService.showGalleryModal(this.listing, this.provider);
    }
  }

  incrementViewCount(event: any) {
    event.preventDefault();
    // this.sessionService.incrementViewCount();
  }

  // private async favoriteItem() {
  //   this.updating = true;
  //   let providerName = MLSPROVIDERS[this.mlsProvider];
  //   try {
  //     // this.savedCard = await this.activityService.toggleFavoriteItem(this.listing.id, providerName, this.listing);
  //     this.savedCard = !this.savedCard;
  //     await this.activityService.toggleFavoriteItem(this.listing.listingId, providerName, this.listing);
  //     await this.statsService.saveListing(this.listing.listingId, providerName, this.savedCard);
  //     this.favoriteEvent.emit([this.listing.listingId, this.savedCard]); // emits the id and true if saved, false if un-saved
  //   } catch (e) {
  //     // console.error(e);
  //     const message = `Failed to save\n${this.listing.address}\n${this.listing.city}, ${this.listing.state} ${this.listing.zipcode}\nPlease try again in a few seconds.`;
  //     this.sessionService.displayApiFailureModal('Sorry, something went wrong', message);
  //     this.savedCard = !this.savedCard;
  //   }
  //   this.updating = false;
  // }

  // toggleFavorite() {
  //   if (this.authService.currentUserValue) {
  //     this.favoriteItem();
  //   } else {
  //     if (this.listing.listingId) {
  //       this.storageService.saveFavoriteItem(this.listing.listingId);
  //     }
  //     this.authModal = this.authService.showLoginModal();
  //     this.authModal.onHidden.subscribe(() => {
  //       if (this.authService.currentUserValue) {
  //         this.favoriteItem();
  //       }
  //     });
  //   }
  // }
}
