import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Unsubscriber } from 'src/app/core/extenders/unsubscriber';
import { RoutingService } from 'src/app/_site-specific/services/routing/routing.service';

@Component({
  selector: 'hch-full-listing-layout',
  templateUrl: './full-listing-layout.component.html',
  styleUrls: ['./full-listing-layout.component.scss'],
})
export class FullListingLayoutComponent extends Unsubscriber implements OnInit {
  constructor(private route: ActivatedRoute, private routingService: RoutingService) {
    super();
  }

  ngOnInit(): void {
    this.addSubscription = this.route.params.subscribe((params) => {
      this.routingService.setActivatedRoute(params);
    });
  }
}
