<div class="position-relative" *ngIf="!mobile" dropdown [insideClick]="true">
  <button class="btn btn-outline-primary btn-md dropdown-toggle" dropdownToggle>More</button>
  <div *dropdownMenu class="dropdown-menu">
    <div class="filter-container">
      <div class="filter-header">
        <h5>Add Filters</h5>
        <button class="btn btn-link" (click)="reset()">reset</button>
      </div>
      <div class="filter-body">
        <ng-container *ngTemplateOutlet="filters"></ng-container>
      </div>
    </div>
  </div>
</div>

<div class="filter-body" *ngIf="mobile">
  <ng-container *ngTemplateOutlet="filters"></ng-container>
</div>

<ng-template #filters>
  <div role="group" aria-labelledby="property-type" class="filter-item">
    <h6 id="property-type" class="mb-3">Property Type</h6>
    <div class="custom-control custom-checkbox checkbox-list-item" *ngFor="let item of propertyTypes; let i = index">
      <input
        type="checkbox"
        class="custom-control-input"
        [id]="'propertyType' + i"
        [(ngModel)]="item.selected"
        (change)="updatePropertyTypes(i)"
      />
      <label class="custom-control-label" [for]="'propertyType' + i">{{ item.label }}</label>
    </div>
  </div>
  <div role="group" aria-labelledby="square-footage" class="filter-item">
    <h6 id="square-footage" class="mb-3">Square Footage</h6>
    <div class="d-flex justify-content-between align-items-center">
      <hch-floating-dropdown
        placeholder="Min"
        category="MIN_SQFT"
        [items]="minFootages"
        [value]="minFootage"
        (change)="updateMinSquareFootage($event)"
      ></hch-floating-dropdown>
      <div class="range"></div>
      <hch-floating-dropdown
        placeholder="Max"
        category="MAX_SQFT"
        [items]="maxFootages"
        [value]="maxFootage"
        (change)="updateMaxSquareFootage($event)"
      ></hch-floating-dropdown>
    </div>
  </div>

  <div role="group" aria-labelledby="lot-size" class="filter-item">
    <h6 id="lot-size" class="mb-3">Lot size</h6>
    <hch-floating-dropdown
      placeholder="Size"
      category="LOT_SIZE"
      [items]="lotSizes"
      [value]="lotSize"
      (change)="updateLotSize($event)"
    ></hch-floating-dropdown>
  </div>

  <div role="group" aria-labelledby="year-built" class="filter-item">
    <h6 id="year-built" class="mb-3">Year Built</h6>
    <div class="d-flex justify-content-between align-items-center">
      <input
        type="text"
        class="form-control"
        [class.is-invalid]="minYear && (minYear < 1900 || minYear > thisYear)"
        placeholder="Min Year"
        [(ngModel)]="minYear"
        (change)="changeMinYear()"
        mask="0000"
        (keyup)="yearBuiltChangeEventHandler($event)"
      />
      <div class="range"></div>
      <input
        type="text"
        class="form-control"
        [class.is-invalid]="maxYear && (maxYear < 1900 || maxYear > thisYear)"
        placeholder="Max Year"
        [(ngModel)]="maxYear"
        (change)="changeMaxYear()"
        mask="0000"
        (keyup)="yearBuiltChangeEventHandler($event)"
      />
    </div>
    <div class="mt-1 text-xs text-danger" *ngIf="maxYear > thisYear || minYear > thisYear">Can't be a future year</div>
  </div>

  <div role="group" aria-labelledby="status" class="filter-item">
    <h6 id="status" class="mb-3">Status</h6>
    <div class="custom-control custom-checkbox checkbox-list-item" *ngFor="let item of status; let i = index">
      <input
        type="checkbox"
        class="custom-control-input"
        [id]="'status' + i"
        [(ngModel)]="item.selected"
        (change)="updateStatus(i)"
      />
      <label class="custom-control-label" [for]="'status' + i">{{ item.label }}</label>
    </div>
  </div>
</ng-template>
