<div class="mobile-header">
  <a class="mobile-logo" [routerLink]="['/homemarketplace']">
    <img src="assets/images/logo-white.svg" alt="logo" />
    <div>
      <strong>HOME</strong>
      CAPTAIN
    </div>
  </a>

  <div class="heart">
    <a routerLink="/homemarketplace/favorite" aria-label="favorites">
      <img src="assets/icons/saved.svg" alt="saved" />
      <div class="saved">saved</div>
    </a>
  </div>
</div>
