<ng-container
  *ngIf="{
    buyParams: routingService.buyQueryParams | async,
    staticParams: routingService.staticQueryParams | async,
    authorized: authService.authorized | async
  } as state"
>
  <li role="menuitem" class="for-mobile mobile-menu-header">
    <div class="menu-header">
      <img src="assets/icons/chevron-left-blue.svg" class="back-btn" alt="Back button" (click)="closeModal()" />
      <img src="assets/images/logo-mobile.svg" alt="Mobile logo" class="mobile-logo" />
      <div></div>
    </div>
    <ng-container *ngIf="!state.authorized">
      <div class="description">Sign in to get the most out of Home Captain</div>
      <button class="btn btn-primary mb-3 d-block w-100" (click)="login()">Sign in</button>
      <div class="d-flex align-items-center">
        Don't have an account?
        <button class="btn btn-link mb-0 ml-3" (click)="register()">Register</button>
      </div>
    </ng-container>
  </li>
  <li class="for-mobile divider dropdown-divider" *ngIf="!state.authorized"></li>

  <div class="d-flex align-items-start px-3" *ngIf="state.authorized">
    <div>
      <div class="mb-2 text-primary font-weight-medium">
        {{ (authService.currentUser | async)?.fullName }}
      </div>
      <button
        class="btn btn-outline-primary btn-sm"
        [routerLink]="routingService.settingsUrl | async"
        [queryParams]="state.staticParams"
      >
        Edit Profile
      </button>
    </div>
  </div>
  <li class="divider dropdown-divider" *ngIf="state.authorized"></li>
  <li role="menuitem">
    <button class="dropdown-item" (click)="onRealEstateAgent()">
      <img src="assets/images/icons/find.png" alt="click to contact a real estate agent" />
      Need a Real Estate Agent?
    </button>
  </li>
  <li class="divider dropdown-divider"></li>
  <li role="menuitem">
    <a
      class="dropdown-item team-item"
      routerLinkActive="team"
      aria-label="Our team"
      [routerLink]="routingService.teamUrl | async"
      [routerLinkActiveOptions]="{ exact: true }"
      [queryParams]="state.staticParams"
    >
      <img src="assets/images/icons/saved-home.png" alt="click to view our team" />
      Our team
    </a>
  </li>
  <li class="divider dropdown-divider"></li>
  <li role="menuitem" *ngIf="state.authorized">
    <a
      class="dropdown-item"
      routerLinkActive="favorite-active"
      aria-label="Saved Searches & Homes"
      [routerLink]="routingService.favoriteUrl | async"
      [routerLinkActiveOptions]="{ exact: true }"
      [queryParams]="state.staticParams"
    >
      <img src="assets/images/icons/saved-home.png" alt="click to see your saved homes and searches" />
      Saved Searches & Homes
    </a>
  </li>
  <li class="divider dropdown-divider" *ngIf="state.authorized"></li>
  <li role="menuitem" *ngIf="state.authorized">
    <a class="dropdown-item" [routerLink]="routingService.settingsUrl | async" [queryParams]="state.staticParams">
      <img src="assets/images/icons/settings.png" alt="click to view your account settings" />
      Account Settings
    </a>
  </li>
  <li class="divider dropdown-divider" *ngIf="state.authorized"></li>
  <li role="menuitem">
    <button class="dropdown-item" (click)="onSupport()">
      <img src="assets/images/icons/headphones.png" alt="click to contact support" />
      Support
    </button>
  </li>
  <li class="divider dropdown-divider" *ngIf="state.authorized"></li>
  <li role="menuitem" *ngIf="state.authorized">
    <button class="dropdown-item" (click)="logout()">
      <img src="assets/images/icons/logout.png" alt="click to logout" />
      Logout
    </button>
  </li>
</ng-container>
