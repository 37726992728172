import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { RoutingService } from 'src/app/_site-specific/services/routing/routing.service';

@Component({
  selector: 'hch-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent implements OnInit {
  @Input() loggedin = false;
  @Output() logoutEvent = new EventEmitter();

  opening = false;
  config = {
    backdrop: false,
    ignoreBackdropClick: false,
  };
  modalRef!: BsModalRef;
  id: any;
  campaignid: any;
  paramsMap: any = {};

  constructor(private modalService: BsModalService, private router: Router, public routingService: RoutingService) {}

  ngOnInit(): void {}

  logout() {
    this.logoutEvent.emit();
  }

  openModal(template: TemplateRef<any>) {
    this.opening = true;
    // CHECK THIS OUT
    // this.router.navigateByUrl('/user-menu');
    this.router.navigateByUrl(this.routingService.userMenuUrl.getValue());
  }

  closeModal() {
    this.modalRef.hide();
    this.opening = false;
  }
}
