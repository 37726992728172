import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { catchError } from 'rxjs';

import { Unsubscriber } from 'src/app/core/extenders/unsubscriber';
import { DropdownItem } from 'src/app/core/models/dropdown.model';

import { ActivityService } from 'src/app/core/services/activity.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { SearchService } from 'src/app/core/services/search.service';
// import { SessionService } from 'src/app/core/services/session.service';

@Component({
  selector: 'hch-save-search-dropdown',
  templateUrl: './save-search-dropdown.component.html',
  styleUrls: ['./save-search-dropdown.component.scss'],
})
export class SaveSearchDropdownComponent extends Unsubscriber implements OnInit {
  @Input() totalElements = 0;

  notiSetting = 'daily';
  notiOptions: DropdownItem[] = [
    { label: 'In Realtime', value: 'realtime' },
    { label: 'Daily updates', value: 'daily' },
    { label: 'No emails', value: 'no-email' },
  ];
  searchName = 'City, State, Zip or Neighborhood';
  searchValue: Params | undefined;
  disabledSearchButton = true;

  enableMore = false;
  authModal!: BsModalRef;

  @ViewChild('savedSearchDropdown') savedSearchDropdown: any;

  constructor(
    private authService: AuthService,
    // private searchService: SearchService,
    private activityService: ActivityService,
    private route: ActivatedRoute,
    // private modalService: BsModalService,
    private modalService: ModalService,
    // private sessionService: SessionService,
    // private storageService: LocalStorageService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.addSubscription = this.route.queryParams.subscribe(() => {
      this.queryParamsChangeHandler();
    });
  }

  queryParamsChangeHandler() {
    const param = this.route.snapshot.queryParams;

    this.searchValue = param;
    this.disabledSearchButton = false;
    let cnt = 0;
    for (let key in param) {
      cnt++;
    }
    this.enableMore = cnt > 7;
    if (param['city']) {
      this.searchName = param['city'] + ' ' + param['state'];
    } else if (param['country']) {
      this.searchName = param['country'];
    } else {
      this.searchName = 'Neighborhood';
    }

    if (!this.searchValue || Object.keys(this.searchValue).length === 0) {
      this.disabledSearchButton = true;
    } else {
      // this.disabledSearchButton = this.searchService.doesSearchValueExist(this.searchValue, true) >= 0;
    }

    this.cdr.markForCheck();
  }

  /**
   * Updates the search with notification settings and a name. Logs the search in HCH database and Salesforce.
   */
  saveSearch() {
    this.disabledSearchButton = true;
    this.savedSearchDropdown.hide();
    // if (this.searchValue) {
    //   this.searchService.setSearchOption(this.searchName, this.notiSetting, this.searchValue, this.totalElements);
    // }
    if (!this.searchValue) return;
    this.addSubscription = this.activityService
      .logSavedSearch({
        name: this.searchName,
        city: this.searchValue['city'],
        state: this.searchValue['state'],
        postalCode: this.searchValue['zip'],
        minBedrooms: this.searchValue['minBedrooms'],
        minBathrooms: this.searchValue['minBathrooms'],
        minPrice: this.searchValue['minPrice'],
        maxPrice: this.searchValue['maxPrice'],
        propertyTypes: this.searchValue['propertyTypes'],
      })
      .pipe(
        catchError((error) => {
          console.error(error);
          if (this.searchValue) {
            const message = `Failed to save your search in\n${this.searchValue['city']}, ${this.searchValue['state']}\nPlease try again in a few seconds.`;
            // this.sessionService.displayApiFailureModal('Sorry, something went wrong', message);
          }
          this.disabledSearchButton = false;
          return error;
        })
      )
      .subscribe();
  }

  closeDropdown() {
    this.savedSearchDropdown.isOpen = false;
  }

  toggleDropdown() {
    if (this.authService.currentUserValue) {
      this.savedSearchDropdown.toggle(true);
    } else {
      this.authModal = this.modalService.showLoginModal();
      this.addSubscription = this.authModal.onHidden?.subscribe(() => {
        if (this.authService.authorized.getValue()) {
          // if (this.searchValue) {
          //   this.storageService.saveTempSearch({
          //     searchName: this.searchName,
          //     notiSetting: this.notiSetting,
          //     value: this.searchValue,
          //     activeListings: this.totalElements,
          //   });
          // }
          this.savedSearchDropdown.toggle(true);
        }
      });
    }
  }
}
